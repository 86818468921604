<template>
  <b-container fluid v-can="'products.create'">
    <b-row>
      <b-col md="12" lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Registro De Nuevo Producto</h4>
          </template>
          <template v-slot:body>
            <div class="new-user-info">
              <register origin="0" />
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import Register from "./forms/createOrUpdate.vue";
export default {
  name: "RegisterProducts",
  components: {
    Register,
  },
  async mounted() {
     this.$store.commit("SET_BREADCRUMB", this.items);
    core.index();
  },
  data() {
    return {
      items: [
        {
          html: 'Inicio',
          to: "/",
        },
        {
          text: "Listado De Productos",
          to: "/products/list",
        },
        {
          text: "Registrar Producto",
          active: true,
        },
      ],
    };
  },
  methods: {},
};
</script>
<style>
.vs__clear {
  margin-right: 5px !important;
  margin-bottom: 3px !important;
}
</style>
